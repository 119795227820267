import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Uptime from "./uptime";
import config from "../config";

const App = () => {
  window.Config = config;

  const { ApiKeys, SiteName } = window.Config;

  useEffect(() => {
    window.document.title = SiteName;
  }, [SiteName]);

  return (
    <>
      <Header />
      <div className="container">
        <div id="uptime">
          {ApiKeys.map((i) => (
            <Uptime key={i} apikey={i} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
