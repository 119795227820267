import React from "react";
import Link from "./link";

const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <p>
          Powered by <Link to="https://uptimerobot.com/" text="UptimeRobot" />{" "}
        </p>
        <p>
          Thanks to <Link to="https://status.org.cn/" text="STATUS.ORG.CN" />
        </p>
        <p>
          Modified by <Link to="https://186526.xyz" text="@186526" />
        </p>
      </div>
    </div>
  );
};

export default Footer;
